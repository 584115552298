import * as React from 'react'
import classNames from 'classnames'
import { PREMIUM_STATUS } from '../../utils/status'
import './Card.css'
import { mappingImageDefault } from '../../utils/mapper'

export type Props = {
  idElement: string
  company: string
  title: string
  status?: PREMIUM_STATUS | null | undefined
  date: string
  image: string
  loading?: boolean
  fontTitle?: string
  startDate?: string
}

export enum STATUS {
  EMPTY = 'สิทธิ์หมด',
  NOT_ENOUGH = 'คอยน์ไม่เพียงพอ',
  EXPIRED = 'หมดเขต',
  COMING_SOON = ''
}

const Card: React.FC<Props> = ({ idElement, company, title, date, status, image, loading, fontTitle, startDate }) => {
  const isDisable = status === PREMIUM_STATUS.COMING_SOON || (status && STATUS[status])
  const disableStyle = { 'opacity-40': isDisable }
  const img = mappingImageDefault(image)
  const detail = status !== PREMIUM_STATUS.COMING_SOON ? `หมดเขต ${date}` : `เริ่มใช้ ${startDate}`

  return (
    <div
      id={idElement}
      className={classNames(
        'flex flex-row p-2.5 shadow-3xl rounded-xl mt-[16px] mx-3 border-gray-50 min-h-30 max-h-30',
        { 'opacity-75': loading }
      )}
    >
      <div
        id={`${idElement}-content`}
        className={classNames(
          'rounded-lg max-w-26 bg-gray-empty flex items-center justify-center mr-2',
          disableStyle
        )}
      >
        {image ? (
          <img
            id={`${idElement}-image`}
            data-testid="card-image"
            src={img}
            className="rounded-lg w-26 max-h-26"
            alt="card"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null
              currentTarget.src = '/icons/coupon-image-placeholder.svg'
              currentTarget.className = 'rounded-lg w-26'
            }}
          />
        ) : (
          <img
            id={`${idElement}-image`}
            src="/icons/coupon-image-placeholder.svg"
            alt="img-placeholder"
            className="rounded-lg w-26"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null
              currentTarget.src = '/icons/coupon-image-placeholder.svg'
              currentTarget.className = 'rounded-lg w-26'
            }}
          />
        )}
      </div>
      <div id={`${idElement}-content-detail`} className="flex flex-col w-4/6 bg-blue font-lotuss">
        <div id={`${idElement}-detail`} data-testid="card-detail" className="h-full mb-2">
          <p
            id={`${idElement}-text-company`}
            data-testid="card-company"
            className={classNames('text-xs leading-4.5 tracking-wide font-medium', {
              'text-gray-secondary': !isDisable,
              'text-gray-inactive': isDisable,
            })}
          >
            {company}
          </p>
          <p
            id={`${idElement}-text-title`}
            data-testid="card-title"
            className={classNames('w3/4 truncate-2-lines text-[14px] leading-5 font-bold', 
            fontTitle,
            {
              'text-gray-primary': !isDisable,
              'text-gray-secondary': isDisable,
            })}
          >
            {title}
          </p>
        </div>
        <div id={`${idElement}-status`} className="bottom-0 bg-red">
          <p
            id={`${idElement}-text-status-loading`}
            data-testid="card-status-loading"
            className={classNames(
              'rounded-lg bg-gradient-to-l from-smoke to-white-smoke w-1/3 h-[12px] mb-2',
              { hidden: !loading && status !== null }
            )}
          />
          <p
            id={`${idElement}-text-status`}
            data-testid="card-status"
            className={classNames('text-xs font-lotuss leading-4.5 text-danger tracking-wide', {
              'text-black text-opacity-0': !status || status === PREMIUM_STATUS.EXPIRED,
              hidden: loading,
            })}
          >
            {status ? STATUS[status] : 'N/A'}
          </p>
          <p id={`${idElement}-text-date`} className={classNames(
            'text-xs leading-4.5 font-medium font-lotuss tracking-wide',
            {
              'text-danger': status === PREMIUM_STATUS.COMING_SOON,
              'text-gray-inactive': status !== PREMIUM_STATUS.COMING_SOON
            }
          )}>
            {detail}
          </p>
        </div>
      </div>
    </div>
  )
}

export default Card
