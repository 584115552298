import { Modal } from '../../common'

interface IProps {
  idElement: string
  isShowModal: boolean
  selectedSortingIndex: number
  setSelectedSortingIndex: Function
  setSelectedSorting: Function
  setIsShowModalSorting: Function
  onSelectedSorting: Function
}

export const sortingList = [
  { title: 'แนะนำ', param: 'sortBy=internalName&sortDirection=asc' },
  { title: 'ใหม่ล่าสุด', param: 'sortBy=startDate&sortDirection=desc' },
  { title: 'ใกล้หมดอายุ', param: 'sortBy=endDate&sortDirection=asc' },
  { title: 'คอยน์ น้อย-มาก', param: 'sortBy=price&sortDirection=asc' },
  { title: 'คอยน์ มาก-น้อย ', param: 'sortBy=price&sortDirection=desc' },
]

export const ModalSorting = ({ idElement, isShowModal, selectedSortingIndex, setSelectedSortingIndex, setSelectedSorting, setIsShowModalSorting, onSelectedSorting }: IProps) => {
  return (
    <Modal idElement={idElement} isShowModal={isShowModal}>
      <div className='flex flex-row justify-between px-[24px] pb-[12px] mt-[28px] border-b-[0.5px] border-[#E0E0E0]'>
        <p className='font-bold text-[#424242] text-lg'>เรียงตาม</p>
        <p 
          className='text-[#00BCB4] leading-6 text-sm' 
          onClick={() => setIsShowModalSorting(!isShowModal)}
        >
          ยกเลิก
        </p>
      </div>
      <div className='px-[24px]'>
        {sortingList.map(({ title, param }, index) => (
          <div 
            key={index} 
            className='flex flex-row justify-center align-top' 
            onClick={() => {
              setSelectedSortingIndex(index)
              setSelectedSorting({ title, param })
              setIsShowModalSorting(!isShowModal)
              onSelectedSorting()
            }}>
            <img 
              src={`/icons/${selectedSortingIndex === index ?  'radio-check.png' : 'radio-uncheck.png'}`}
              alt='toggle-active' 
              className='w-[24px] h-[24px] my-[16px]'
            />
            <div className='border-b-[0.5px] border-[#E0E0E0] w-full py-[16px] text-[#424242] leading-6 ml-[8px]'>
              {title}
            </div>
          </div>
        ))}
      </div>
    </Modal>
  )
}
