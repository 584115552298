
export const wait = (ms: number) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(true)
        }, ms)
    })
}

export const waitTokenChange = async (expiredToken: any) => {
    while(expiredToken === localStorage.getItem('bzb_user_token')) {
        await wait(500)
    }
}
