import { Coupon, OfferDetails, PartnerOfferDetails, PartnerOffers, Profile, CampaignCategory, CouponsBzbDetails } from './types'
import * as Fetch from '../../utils/fetch'
import { HttpErrorResponse } from '../../utils/reponseType'
import { generateXRequestId } from '../../utils/uuid4'

export const getProfile = async (): Promise<Fetch.HttpResponse | Profile> => {
  const url = `${process.env.REACT_APP_SERVICE_URL}/v1/me?fields=name,points`
  const xRequestId = generateXRequestId()
  const options = {
    headers: {
      method: 'GET',
      'x-request-id': xRequestId,
      'Content-Type': 'application/json',
    },
  }

  try {
    const result = await Fetch.get(url, options)
    if (result.httpStatusCode === 200) {
      return result.json.data as Profile
    } else if (result.httpStatusCode === 401) {
      throw new HttpErrorResponse(401, xRequestId)
    }
    throw result
  } catch (error: any) {
    if (error instanceof HttpErrorResponse) {
      throw error
    }
    throw new HttpErrorResponse(error?.httpStatusCode || 500, xRequestId)
  }
}

export const getOffers = async (sort: string): Promise<OfferDetails[]> => {
  const url = `${process.env.REACT_APP_SERVICE_URL}/v1/offers?${sort}`
  const xRequestId = generateXRequestId()

  const options = {
    headers: {
      method: 'GET',
      'x-request-id': xRequestId,
      'Content-Type': 'application/json',
    },
  }

  try {
    const result = await Fetch.get(url, options)

    if (result.httpStatusCode === 200) {
      return result.json.data as OfferDetails[]
    }
    throw result
  } catch (error: any) {
    throw new HttpErrorResponse(error.httpStatusCode || 500, xRequestId)
  }
}

export const getAvailableInventoryOffers = async (sort: string): Promise<Fetch.HttpResponse | OfferDetails[]> => {
  const url = `${process.env.REACT_APP_SERVICE_URL}/v1/offers?fields=availableInventoryRemaining&${sort}`
  const xRequestId = generateXRequestId()

  const options = {
    headers: {
      method: 'GET',
      'x-request-id': xRequestId,
      'Content-Type': 'application/json',
    },
  }

  try {
    const result = await Fetch.get(url, options)

    if (result.httpStatusCode === 200) {
      return result.json.data as OfferDetails[]
    }
    throw result
  } catch (error: any) {
    throw new HttpErrorResponse(error.httpStatusCode || 500, xRequestId)
  }
}

export const getCoupons = async (token: string): Promise<Fetch.HttpResponse | Coupon[]> => {
  const url = `${process.env.REACT_APP_SERVICE_URL}/v1/me/coupons?type=Premium`
  const xRequestId = generateXRequestId()

  const options = {
    headers: {
      method: 'GET',
      'x-request-id': xRequestId,
      'Content-Type': 'application/json',
    },
  }

  try {
    const result = await Fetch.get(url, options)

    if (result.httpStatusCode === 200) {
      const coupons: Coupon[] = result.json.data

      return coupons
    }
    throw result
  } catch (error: any) {
    throw new HttpErrorResponse(error.httpStatusCode || 500, xRequestId)
  }
}

export const getCouponsPowerUpAndPremium = async (
  token: string
): Promise<Fetch.HttpResponse | Coupon[]> => {
  const url = `${process.env.REACT_APP_SERVICE_URL}/v2/me/coupons?type=Premium,PowerUp`
  const xRequestId = generateXRequestId()

  const options = {
    headers: {
      method: 'GET',
      Authorization: token,
      'x-request-id': xRequestId,
      'Content-Type': 'application/json',
    },
  }

  try {
    const result = await Fetch.get(url, options)

    if (result.httpStatusCode === 200) {
      const coupons: Coupon[] = result.json.data

      return coupons
    }
    throw result
  } catch (error: any) {
    throw new HttpErrorResponse(error.httpStatusCode || 500, xRequestId)
  }
}

export const redeemingOffer = async (
  id: string,
  offerType?: string,
  bzbToken?: string
): Promise<Fetch.HttpResponse | Coupon | CouponsBzbDetails> => {
  const url = `${process.env.REACT_APP_SERVICE_URL}/v1/me/coupons`
  const xRequestId = generateXRequestId()

  const options = {
    headers: {
      'x-request-id': xRequestId,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ 
      offer_id: id,
      offer_type: offerType ? offerType : 'reward-store',
      ...(bzbToken && {bzb_token: bzbToken })
    }),
  }

  try {
    const result = await Fetch.post(url, options)
    if (result.httpStatusCode === 201) {
      const data = result.json.data

      return data
    }

    throw result
  } catch (error: any) {
    throw new HttpErrorResponse(error.json.code || 500, xRequestId, error?.json?.error)
  }
}

export const getBZBCouponDetails = async (
  couponId: string,
  bzbToken?: string
): Promise<Fetch.HttpResponse | CouponsBzbDetails> => {
  const url = `${process.env.REACT_APP_SERVICE_URL}/v2/me/coupons?redeem_key=${couponId}&bzb_token=${bzbToken}&type=bzb`
  const xRequestId = generateXRequestId()

  const options = {
    headers: {
      'x-request-id': xRequestId,
      'Content-Type': 'application/json',
    }
  }

  try {
    const result = await Fetch.get(url, options)

    if (result.httpStatusCode === 200) {
      return result.json.data[0] as CouponsBzbDetails
    }
    throw new HttpErrorResponse(result.httpStatusCode || 500, xRequestId)
  } catch (error: any) {
    throw new HttpErrorResponse(error.json.code || 500, xRequestId, error?.json?.error)
  }
}

export const getBZBCoupons = async (
  bzbToken?: string
): Promise<Fetch.HttpResponse | CouponsBzbDetails[]> => {
  const url = `${process.env.REACT_APP_SERVICE_URL}/v2/me/coupons?bzb_token=${bzbToken}&type=bzb`
  const xRequestId = generateXRequestId()

  const options = {
    headers: {
      'x-request-id': xRequestId,
      'Content-Type': 'application/json',
    }
  }

  try {
    const result = await Fetch.get(url, options)

    if (result.httpStatusCode === 200) {
      return result.json.data as CouponsBzbDetails[]
    }

    throw new HttpErrorResponse(result.httpStatusCode || 500, xRequestId)
  } catch (error: any) {
    throw new HttpErrorResponse(error.json.code || 500, xRequestId, error?.json?.error)
  }
}

export const getLotusCoupons = async (token: string): Promise<Fetch.HttpResponse | Coupon[]> => {
  const url = `${process.env.REACT_APP_SERVICE_URL}/v2/me/coupons?type=Voucher,Online,SpecialForYou`
  const xRequestId = generateXRequestId()

  const options = {
    headers: {
      method: 'GET',
      'x-request-id': xRequestId,
      'Content-Type': 'application/json',
    },
  }

  try {
    const result = await Fetch.get(url, options)

    if (result.httpStatusCode === 200) {
      const coupons: Coupon[] = result.json.data
      const data = coupons.filter((data) => data.category) as Coupon[]

      return data
    }
    throw new HttpErrorResponse(result.httpStatusCode || 500, xRequestId)
  } catch (error: any) {
    throw new HttpErrorResponse(error.httpStatusCode || 500, xRequestId)
  }
}

export const getLotusCouponsByType = async (type: string): Promise<Fetch.HttpResponse | Coupon[]> => {
  const url = `${process.env.REACT_APP_SERVICE_URL}/v2/me/coupons?type=${type}`
  const xRequestId = generateXRequestId()

  const options = {
    headers: {
      method: 'GET',
      'x-request-id': xRequestId,
      'Content-Type': 'application/json',
    },
  }

  try {
    const result = await Fetch.get(url, options)

    if (result.httpStatusCode === 200) {
      return result.json.data as Coupon[]
    }
    throw new HttpErrorResponse(result.httpStatusCode || 500, xRequestId)
  } catch (error: any) {
    throw new HttpErrorResponse(error.httpStatusCode || 500, xRequestId)
  }
}

export const getPartnerOffers = async (token: string, bzbToken: string): Promise<Fetch.HttpResponse | PartnerOffers[]> => {
  const url = `${process.env.REACT_APP_SERVICE_URL}/v1/partner-offers?bzb_token=${bzbToken}`
  const xRequestId = generateXRequestId()
  const options = {
    headers: {
      method: 'GET',
      Authorization: token,
      'x-request-id': xRequestId,
      'Content-Type': 'application/json',
    },
  }

  try {
    const result = await Fetch.get(url, options)
    if (result.httpStatusCode === 200) {
      const partnerOffers: PartnerOffers[] = result.json.data
      return partnerOffers
    }
    throw result
  } catch (error: any) {
    console.log({ error })
    throw new HttpErrorResponse(error.httpStatusCode || 500, xRequestId)
  }
}

export const getPartnerOffersDetails = async (token: string, id: number, bzbToken: string, bzbUerId: string): Promise<Fetch.HttpResponse | PartnerOfferDetails> => {
  const url = `${process.env.REACT_APP_SERVICE_URL}/v1/partner-offers/${id}?bzb_token=${bzbToken}&bzb_user_id=${bzbUerId}`
  const xRequestId = generateXRequestId()
  const options = {
    headers: {
      method: 'GET',
      Authorization: token,
      'x-request-id': xRequestId,
      'Content-Type': 'application/json',
    },
  }

  try {
    const result = await Fetch.get(url, options)
    if (result.httpStatusCode === 200) {
      const partnerOfferDetails: PartnerOfferDetails = result.json.data
      return partnerOfferDetails
    } else if (result.httpStatusCode === 401) {
      return { TokenExpired: true } as PartnerOfferDetails
    }
    throw result
  } catch (error: any) {
    throw new HttpErrorResponse(error.httpStatusCode || 500, xRequestId)
  }
}

export const getCampaignCategory  = async (token: string): Promise<CampaignCategory[]> => {
  const url = `${process.env.REACT_APP_SERVICE_URL}/v1/partner-offer-categories`
  const xRequestId = generateXRequestId()
  const options = {
    headers: {
      method: 'GET',
      Authorization: token,
      'x-request-id': xRequestId,
      'Content-Type': 'application/json',
    },
  }

  try {
    const result = await Fetch.get(url, options)
    if (result.httpStatusCode === 200) {
      return result.json.data as CampaignCategory[]
    }
    throw result
  } catch (error: any) {
    throw new HttpErrorResponse(error.httpStatusCode || 500, xRequestId)
  }
}

export const getPartnerOffersWithBothLang = async (
  token: string,
  bzbToken: string
): Promise<Fetch.HttpResponse | PartnerOffers[]> => {
  const url = `${process.env.REACT_APP_SERVICE_URL}/v1/partner-offers?bzb_token=${bzbToken}&lang=th,en`
  const xRequestId = generateXRequestId()
  const options = {
    headers: {
      method: 'GET',
      Authorization: token,
      'x-request-id': xRequestId,
      'Content-Type': 'application/json',
    },
  }

  try {
    const result = await Fetch.get(url, options)
    if (result.httpStatusCode === 200) {
      const partnerOffers: PartnerOffers[] = result.json.data
      return partnerOffers
    }
    throw result
  } catch (error: any) {
    throw new HttpErrorResponse(error.httpStatusCode || 500, xRequestId)
  }
}
