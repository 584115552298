import dayjs from 'dayjs'
import 'dayjs/locale/th'
import buddhistEra from 'dayjs/plugin/buddhistEra'

dayjs.locale('th')
dayjs.extend(buddhistEra)

interface Status {
  startDate?: string, 
  endDate: string, 
  point: number | undefined, 
  price: number | null, 
  available?: number | null
}

export enum PREMIUM_STATUS {
  EMPTY = 'EMPTY',
  NOT_ENOUGH = 'NOT_ENOUGH',
  EXPIRED = 'EXPIRED',
  COMING_SOON = 'COMING_SOON',
  VALID = ''
}

export const isExpired = (date: string) => {
  const dateNow = dayjs(new Date())
  if (dayjs(date).isBefore(dateNow)) {
    return PREMIUM_STATUS.EXPIRED
  }
}

export const isComingSoon = (date?: string) => {
  let dateNow = dayjs(new Date(), 'DD MMM BBBB, HH:mm').toISOString()

  if (!date) return false

  return dayjs(dateNow).isBefore(dayjs(date))
}

export const getStatus = ({ startDate, endDate, point, price, available }: Status) => {
  let status = PREMIUM_STATUS.VALID

  if (isComingSoon(startDate)) {
    return PREMIUM_STATUS.COMING_SOON
  } else {
    if (isExpired(endDate)) {
      status = PREMIUM_STATUS.EXPIRED
    }
    if ((point || point === 0) && (price && point < price)) {
      status = PREMIUM_STATUS.NOT_ENOUGH
    }
    if (!available && available === 0) {
      status = PREMIUM_STATUS.EMPTY
    }
    if (point === undefined) {
      status = PREMIUM_STATUS.VALID
    }
  
  }

  return status
}

export const getStatusOffer = ({ startDate, endDate, point, price, available }: Status) => {
  let status = PREMIUM_STATUS.VALID

  if (isComingSoon(startDate)) {
    status = PREMIUM_STATUS.COMING_SOON
  } else if (isExpired(endDate)) {
    status = PREMIUM_STATUS.EXPIRED
  } else if (!available && available === 0) {
    status = PREMIUM_STATUS.EMPTY
  } else if ((price && point) && (point < price)) {
    status = PREMIUM_STATUS.NOT_ENOUGH
  }

  return status
}

export enum OFFERS_SPECIAL_STORE_STATUS {
  EMPTY = 'EMPTY',
  NOT_ENOUGH = 'NOT_ENOUGH',
  REMAINDER = 'REMAINDER',
  VALID = ''
}

export const getStatusOffersSpecialStore = (point?: number, pointPerUnit?: number, qty?: number) => {
  let status = OFFERS_SPECIAL_STORE_STATUS.VALID

  if ((point || point === 0) && ((pointPerUnit || pointPerUnit === 0) && point < pointPerUnit)) {
    status = OFFERS_SPECIAL_STORE_STATUS.NOT_ENOUGH
  }
  if (qty && qty <= 200) {
    status = OFFERS_SPECIAL_STORE_STATUS.REMAINDER
  }
  if (!qty && qty === 0) {
    status = OFFERS_SPECIAL_STORE_STATUS.EMPTY
  }

  return status
}